var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-xl text-center login-card",attrs:{"loading":_vm.loading,"elevation":"4"}},[_c('v-card-text',{staticClass:"pa-8"},[_c('img',{attrs:{"src":require("@/assets/logo.svg"),"width":"128px"}}),_c('h2',{staticClass:"my-8"},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.pages.login.login")))]),_c('p',{staticClass:"px-4 mb-10"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.pages.login.mainText"))+" ")]),_c('v-form',{ref:"form",staticClass:"mx-2 mx-sm-10",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{ref:"enterMobile",staticClass:"my-2",attrs:{"value":_vm.mobile,"label":_vm.$vuetify.lang.t('$vuetify.pages.login.mobileNumber'),"outlined":"","name":"mobile","autocomplete":"mobile","rules":[
          _vm.$globalRules.required(
            _vm.$vuetify.lang.t("$vuetify.validation.required")
          )[0],
          _vm.$globalRules.mobile(
            _vm.$vuetify.lang.t("$vuetify.validation.mobileNumber")
          )[0]
        ],"counter":11,"type":"tel","required":"","dense":""},on:{"input":_vm.inputMobile}}),_c('v-btn',{attrs:{"depressed":"","color":"primary","block":"","type":"submit","disabled":!_vm.valid}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.pages.login.send"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }