<template>
  <v-card
    :loading="loading"
    class="rounded-xl text-center login-card"
    elevation="4"
  >
    <v-card-text class="pa-8">
      <img src="@/assets/logo.svg" width="128px" />
      <h2 class="my-8">
        {{ $vuetify.lang.t("$vuetify.pages.login.confirmMobileNumber") }}
      </h2>
      <p class="px-4">
        {{ $store.getters["auth/getSendCodeMessage"] | persianNumber() }}
      </p>
      <v-btn class="my-3" color="primary" text @click="clearMobile">
        {{ $vuetify.lang.t("$vuetify.pages.login.editNumber") }}
      </v-btn>
      <v-form
        ref="form"
        v-model="valid"
        class="mx-2 mx-sm-10"
        @submit.prevent="submit"
      >
        <v-text-field
          ref="enterCode"
          :value="loginCode"
          @input="inputLoginCode"
          :label="$vuetify.lang.t('$vuetify.pages.login.verificationCode')"
          outlined
          name="verificationCode"
          type="text"
          inputmode="numeric"
          autocomplete="one-time-code"
          :rules="[
            $globalRules.required(
              $vuetify.lang.t(`$vuetify.validation.required`)
            )[0],
            $globalRules.counter($vuetify.lang.t(`$vuetify.validation.wrong`), {
              exact: 6
            })[0]
          ]"
          :counter="6"
          required
          dense
          class="my-2"
        >
        </v-text-field>
        <v-btn depressed color="primary" block type="submit" :disabled="!valid">
          {{ $vuetify.lang.t("$vuetify.pages.login.send") }}
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { englishNumber } from "@/tools/functions";

export default {
  props: {
    hasMobile: Boolean
  },
  data: function() {
    return {
      valid: false,
      loginCode: ""
    };
  },
  watch: {
    hasMobile(val) {
      if (val) {
        const timer = setTimeout(() => {
          clearTimeout(timer);
          this.$refs.enterCode.focus();
        }, 100);
      }
    }
  },
  computed: {
    loading() {
      return this.$store.getters["auth/getRequest"]("login");
    },
    referer() {
      return this.$store.getters["auth/getReferer"];
    }
  },
  methods: {
    clearMobile() {
      this.$store.commit("auth/updateMobile", undefined);
    },
    inputLoginCode(value) {
      this.loginCode = englishNumber(value);
    },
    submit() {
      if (this.valid) {
        this.$store.dispatch("auth/login", {
          code: this.loginCode,
          cb: res => {
            if (res) {
              if (this.referer) {
                this.$router.replace(this.referer);
              } else {
                this.$router.replace({
                  name: "dashboard"
                });
              }
            }
          }
        });
      }
    }
  },
  created() {
    if (window.OTPCredential) {
      window.addEventListener("DOMContentLoaded", () => {
        const ac = new AbortController();
        navigator.credentials
          .get({
            otp: {
              transport: ["sms"]
            },
            signal: ac.signal
          })
          .then(otp => {
            this.loginCode = otp.code;
            this.submit();
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.login-card {
  width: 95%;
  margin: auto;
  left: 2.5%;
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    width: 450px;
    left: 0;
  }
}
</style>
