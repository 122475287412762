<template>
  <v-card
    :loading="loading"
    class="rounded-xl text-center login-card"
    elevation="4"
  >
    <v-card-text class="pa-8">
      <img src="@/assets/logo.svg" width="128px" />
      <h2 class="my-8">{{ $vuetify.lang.t("$vuetify.pages.login.login") }}</h2>
      <p class="px-4 mb-10">
        {{ $vuetify.lang.t("$vuetify.pages.login.mainText") }}
      </p>
      <v-form
        ref="form"
        v-model="valid"
        class="mx-2 mx-sm-10"
        @submit.prevent="submit"
      >
        <v-text-field
          ref="enterMobile"
          :value="mobile"
          @input="inputMobile"
          :label="$vuetify.lang.t('$vuetify.pages.login.mobileNumber')"
          outlined
          name="mobile"
          autocomplete="mobile"
          :rules="[
            $globalRules.required(
              $vuetify.lang.t(`$vuetify.validation.required`)
            )[0],
            $globalRules.mobile(
              $vuetify.lang.t(`$vuetify.validation.mobileNumber`)
            )[0]
          ]"
          :counter="11"
          type="tel"
          required
          dense
          class="my-2"
        >
        </v-text-field>
        <v-btn depressed color="primary" block type="submit" :disabled="!valid">
          {{ $vuetify.lang.t("$vuetify.pages.login.send") }}
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { englishNumber } from "@/tools/functions";

export default {
  data: function() {
    return {
      valid: false,
      mobile: ""
    };
  },
  computed: {
    loading() {
      return this.$store.getters["auth/getRequest"]("codeRequest");
    }
  },
  methods: {
    inputMobile(value) {
      this.mobile = englishNumber(value);
    },
    submit() {
      if (this.valid) {
        this.$store.dispatch("auth/codeRequest", this.mobile);
      }
    }
  },
  mounted() {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      this.$refs.enterMobile.focus();
    }, 100);
  }
};
</script>

<style lang="scss" scoped>
.login-card {
  width: 95%;
  margin: auto;
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    width: 450px;
  }
}
</style>
