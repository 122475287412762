<template>
  <v-container class="cyan" fill-height fluid>
    <v-row align="center" justify="center">
      <div class="relative-position">
        <enter-code
          :hasMobile="hasMobile"
          :class="['enter-code-card', { hasMobile }]"
        ></enter-code>
        <enter-mobile
          :hasMobile="hasMobile"
          :class="['enter-mobile-card', { hasMobile }]"
        ></enter-mobile>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import EnterMobile from "./EnterMobile";
import EnterCode from "./EnterCode";

export default {
  computed: {
    hasMobile() {
      return this.$store.getters["auth/hasMobile"];
    }
  },
  components: {
    EnterMobile,
    EnterCode
  }
};
</script>

<style lang="scss" scoped>
.enter-code-card {
  opacity: 0.5;
  position: absolute;
  bottom: 0;
  transform: scale(0.9) translate(0, 40px);
  transform-origin: 50% 50%;
  transition: transform 500ms cubic-bezier(0.61, 1, 0.88, 1),
    scale 500ms cubic-bezier(0.61, 1, 0.88, 1), opacity 500ms linear;
  &.hasMobile {
    opacity: 1;
    transform: scale(1) translate(0, 0);
  }
}
.enter-mobile-card {
  opacity: 1;
  top: 0;
  transform: scale(1) translate(0, 0);
  transform-origin: 50% 50%;
  transition: transform 500ms cubic-bezier(0.61, 1, 0.88, 1),
    scale 500ms cubic-bezier(0.61, 1, 0.88, 1), opacity 200ms linear;
  &.hasMobile {
    opacity: 0;
    transform: scale(1.2) translate(0, -100vh);
  }
}
</style>
